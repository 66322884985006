import { useContext, useState, useRef, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";
import DocumentComponent from './DocumentComponent';
import styles from './DocumentsComponent.module.css';
import { Callbacks } from 'jquery';

const DocumentsComponent = () => {
    const { documents, selectedDocument, setSelectedDocument,  socketRef, activeConference, setActiveConference, currentUser, documentsFilter, filteredDocuments, setFilteredDocuments } = useContext(GlobalContext);
    const deleteModelRef = useRef();
    const editModelRef = useRef();
    const editDocumentNameRef = useRef();
    const shareModelRef = useRef();
    const inputFile = useRef();

    const [usersMap, setUsersMap] = useState([]);
    const [fileData, setFileData] = useState(null);
    const [fileName, setFileName] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [currentTopics, setCurrentTopics] = useState([]);
    const [selectedTopic, setSelectedTopic] = useState(null);

    useEffect(() => {
        if (selectedDocument) {
            editDocumentNameRef.current.value = selectedDocument.name;
            
            socketRef.current.emit("GET_ALL_USERS", (users) => {
                const filteredUsers = users.filter(user => (
                    !selectedDocument.users.some(docUser => docUser._id === user._id) &&
                    !selectedDocument.pending_users.some(pendingUser => pendingUser === user._id)
                ));
                
                setUsersMap(filteredUsers);
            });
        }
    }, [selectedDocument]);
    

    useEffect(() => {
        socketRef.current.emit("DOCS_GET_ACTIVE_CONFERENCE", currentUser._id, (callback) => {
            if (!callback.error) {
                setActiveConference(callback.response)
            } else {
                console.log(callback.response)
            }
        });
    }, []);


    useEffect(() => {
        if (activeConference && selectedDocument) {
            socketRef.current.emit("DOCS_GET_TOPICS_FROM_ACTIVE_CONFERENCE", { conferenceId: activeConference._id, docId: selectedDocument._id, userId: currentUser._id }, (callback) => {
                if (!callback.error) {
                    console.log(callback.response)
                    setCurrentTopics(callback.response)
                } else {
                    console.log(callback.response)
                }
            })
        };
    }, [activeConference, selectedDocument]);

    const closeAlert = () => setAlertMessage(null);

    const allowedTypes = () => {
        switch (documentsFilter) {
            case "doc": return ".docx, .doc";
            case "sheet": return ".xlsx, .xls";
            case "image": return ".jpg, .jpeg, .png, .gif, .svg";
            case "video": return ".mp4, .avi, .webm, .mpeg";
            case "pdf": return ".pdf";
            default: return ".docx, .doc, .xlsx, .xls, .jpg, .jpeg, .png, .gif, .svg, .mp4, .avi, .webm, .mpeg, .pdf";
        }
    }

    const addDocument = () => {
        const type = fileData ? fileData.type : null;

    

        const documentDetails = {
            ownerId: currentUser._id,
            uploadFile: fileData,
            type,
            fileName: fileName || editDocumentNameRef.current.value,
            docId: selectedDocument ? selectedDocument._id : null
        };

        console.log(documentDetails)

        socketRef.current.emit("DOCS_ADD_EDIT_DOCUMENT", documentDetails, (response) => {
            if (!response._id) {
                console.log('Error while uploading file');
            }
            console.log(response)
            setFileName('');
            inputFile.current.value = null;
            setSelectedDocument(null);
        });
    }

    const deleteDocument = () => {
        socketRef.current.emit("DOCS_DELETE_DOCUMENT", { docId: selectedDocument._id, userId: currentUser._id }, (response, projects) => {
            if (response === '400') {
                setAlertMessage(`Please delete the file from the following projects first: ${formatProjectNames(projects)}`);
            }
            setSelectedDocument(null);
        });
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileData(file);
    }

    const handleSubmitShare = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const selectedUsers = formData.getAll('userSelect');
        socketRef.current.emit("DOCS_SHARE_DOCUMENT", { userIDs: selectedUsers, documentId: selectedDocument._id });

        event.target.reset();
        setSelectedDocument(null);
        setSelectedTopic(null)

    };
    const handleSubmitSendTo = (event) => {
        event.preventDefault();
        if (selectedTopic) {
            socketRef.current.emit("DOCS_ADD_DOC_TO_TOPIC_CONFERENCE", {
                conferenceId: activeConference._id,
                topicId: selectedTopic,
                docId: selectedDocument._id,
                userId: currentUser._id
            }, (callback) => {
                console.log(callback);
            });

            event.target.reset();
            setSelectedDocument(null);
            setSelectedTopic(null); // Clear selected topic
        }
    };

    const formatProjectNames = (projects) => projects.map(project => project.name).join(", ");

    return (
        <>
            <main id="main" className="main">
                {alertMessage && (
                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                        <i className="bi bi-exclamation-octagon me-1"></i>
                        {alertMessage}
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="alert" aria-label="Close" onClick={closeAlert}></button>
                    </div>
                )}
                <div className="pagetitle">
                    <h1>Documents</h1>
                </div>
                <section className="section dashboard">
                    <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    <div className="iconslist">
                        {(filteredDocuments && filteredDocuments.length > 0 ? filteredDocuments : documents).map((document) => (
                            <DocumentComponent key={document._id} document={document} activeConference={activeConference} />
                        ))}
                    </div>
                </section>

                <div className="modal fade" id="deleteModel" tabIndex="-4" ref={deleteModelRef}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Delete Modal</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete the file <b>{selectedDocument?.name}</b>?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedDocument(null)}>Close</button>
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={deleteDocument}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="editModel" tabIndex="-2" ref={editModelRef}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Edit File</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-floating mb-3">
                                    <input ref={editDocumentNameRef} placeholder='File name' className="form-control" id="floatingInput" />
                                    <label htmlFor="floatingInput">File name</label>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedDocument(null)}>Close</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={addDocument}>Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="sendToModel" tabIndex="-3" ref={shareModelRef}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Send File to {activeConference ? activeConference.name : ''} Topic</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={handleSubmitSendTo}>
                                <div className="card">
                                    {currentTopics && currentTopics.length > 0 ? (
                                        <div className="card-body">
                                            <h5 className="card-title">Select Names</h5>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Select</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {currentTopics.map((topic, index) => (
                                                        <tr key={topic._id}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{topic.name}</td>
                                                            <td>
                                                                <input
                                                                    type="radio"
                                                                    name="topicSelect"
                                                                    value={topic._id}
                                                                    checked={selectedTopic === topic._id}
                                                                    onChange={() => setSelectedTopic(topic._id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : (
                                        <p style={{ textAlign: 'center', padding: '20px' }}>File already in project</p>
                                    )}
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedDocument(null)}>Close</button>
                                    <button type="submit" className="btn btn-success" data-bs-dismiss="modal" disabled={!selectedTopic}>Share</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="shareModel" tabIndex="-3" ref={shareModelRef}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Share File</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={handleSubmitShare}>
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="card-title">Select Names</h5>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Select</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {usersMap.map((user, index) => (
                                                    <tr key={user._id}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{user.name}</td>
                                                        <td>
                                                            <input type="checkbox" name="userSelect" value={user._id} />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedDocument(null)}>Close</button>
                                    <button type="submit" className="btn btn-success" data-bs-dismiss="modal">Share</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="addModel" tabIndex="-2" ref={editModelRef}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add File</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-floating mb-3">
                                    <input className="form-control" id="floatingInput" name="name" value={fileName} placeholder='File name' onChange={(e) => setFileName(e.target.value)} required />
                                    <label htmlFor="floatingInput">File name</label>
                                </div>
                                <input name="browseFile" ref={inputFile} type="file" className="form-control" accept={allowedTypes()} onChange={handleFileChange} required />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => setSelectedDocument(null)}>Close</button>
                                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={addDocument}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <div className={styles.plus_icon} onClick={() => setSelectedDocument(null)}>
                <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#addModel">
                    <i className="bi bi-plus" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}></i>
                </a>
            </div>
        </>
    );
}

export default DocumentsComponent;
