import { useContext, useEffect, useRef } from 'react';
import { GlobalContext } from "../contexts/globalContext"
import io from "socket.io-client"

const SocketManager = () => {
    const { currentNote, devices, socketRef, setActiveConference, setProjects, currentProject, setCurrentProject, setDocuments, currentUser, documentsFilter, setPendingDocuments, setCurrentNote, } = useContext(GlobalContext);
    const devicesRef = useRef();
    const documentsFilterRef = useRef();
    const noteRef = useRef();
    const selectedProjectIDRef = useRef();

    useEffect(() => {
        devicesRef.current = devices;
    }, [devices])

    useEffect(() => {
        selectedProjectIDRef.current = currentProject?._id;
    }, [currentProject])

    useEffect(() => {
        documentsFilterRef.current = documentsFilter;
    }, [documentsFilter])

    useEffect(() => {
        noteRef.current = currentNote;
    }, [currentNote])

    useEffect(() => {
        var token = currentUser.token;

        socketRef.current = io.connect(process.env.REACT_APP_CMS_URL, {
            secure: true,
            reconnect: true,
            rejectUnauthorized: true,
            query: { token }
        })


        socketRef.current.on('connect', function () {

            console.log('connected')

            //socketRef.current.emit("NEW_WEB_CLIENT", currentUser._id);

            // socketRef.current.emit("GET_USER_DOCUMENTS", { userId: currentUser._id, filter: documentsFilter }, (documents, pending_documents) => {
            //     setDocuments(documents);
            //     setPendingDocuments(pending_documents)
            // });

            socketRef.current.on('DOCS_REFRESH_DOCUMENTS', async (filter) => {
                console.log('Refresh', filter)
                if (Array.isArray(filter)) {
                    if (filter.includes(documentsFilterRef.current)) {
                        socketRef.current.emit("DOCS_USER_DOCUMENTS", { userId: currentUser._id, filter: documentsFilterRef.current }, (documents) => {
                            setDocuments(documents);
                        });
                    }
                } else {
                    if (filter == documentsFilterRef.current) {
                        socketRef.current.emit("DOCS_USER_DOCUMENTS", { userId: currentUser._id, filter: documentsFilterRef.current }, (documents) => {
                            setDocuments(documents);
                        });
                    }
                }
            })

            socketRef.current.on('REFRESH_PROJECTS', async () => {
                console.log("se cere ref projects");
                if (!documentsFilterRef.current) {
                    socketRef.current.emit("DOCS_GET_ALL_PROJECTS", currentUser._id, true, null, (projects) => {
                        setProjects(projects);
                    });
                }
            })

            socketRef.current.on('DOCS_UPDATE_ACTIVE_CONFERENCE', async () => {
                socketRef.current.emit("DOCS_GET_ACTIVE_CONFERENCE", currentUser._id, (callback) => {
                    if (!callback.error) {
                        setActiveConference(callback.response)
                    } else {
                        console.log(callback.response)
                    }
                });
            })

            socketRef.current.on('REFRESH_PENDING_DOCUMENTS', async () => {
                socketRef.current.emit("DOCS_USER_PENDING_DOCUMENTS", { userId: currentUser._id }, (pending_documents) => {
                    setPendingDocuments(pending_documents);
                });
            })

            socketRef.current.on('REFRESH_NOTE', async (note) => {
                setCurrentNote(note);
            })

            socketRef.current.on('REFRESH_PROJECT', async () => {
                socketRef.current.emit("DOCS_GET_PROJECT_BY_ID",  selectedProjectIDRef.current, (project) => {
                    setCurrentProject(project)
                    console.log(project)
                })
            })
            // TO EXPLAIN
            //- se fac prea multe calluri la emituri
            // this.webClients[userId] = socket.id;   trebuia pus la connection
            // socketRef.current.on('REFRESH_DOCUMENTS', async (filter) => {
            //     socketRef.current.emit("GET_USER_DOCUMENTS", { userId: currentUser._id, filter: filter }, (documents, pending_documents) => {
            //         setDocuments(documents);
            //         setPendingDocuments(pending_documents)
            //         setDocumentsFilter(filter)
            //     });
            // })
        });
    }, [])

    return (
        <> </>
    );
}

export default SocketManager;