import { React, useContext, useState, useEffect } from 'react';
import { GlobalContext } from "../../contexts/globalContext";

const DocumentComponent = ({ document, activeConference }) => {

    const [iconClassName, setIconClassName] = useState("");

    const [editForm, setEditForm] = useState(false);
    const [deleteForm, setDeleteForm] = useState(false);
    const [shareForm, setShareForm] = useState(false);
    const { currentUser, socketRef, setSelectedDocument } = useContext(GlobalContext);

    const isOwner = document.users.length > 0 && document.users[0]._id === currentUser._id;

    useEffect(() => {
        switch (document.type) {
            case "doc":
                setIconClassName("bi bi-file-earmark-text");
                break;
            case "sheet":
                setIconClassName("bi bi-file-earmark-spreadsheet");
                break;
            case "image":
                setIconClassName("bi bi-file-easel");
                break;
            case "video":
                setIconClassName("bi bi-file-slides");
                break;
            case "pdf":
                setIconClassName("bi bi-file-earmark-pdf");
                break;
            default:
                setIconClassName("bi bi-file-earmark-text");
                break;
        }
    }, [document.type]);

    const openModalEdit = () => {
        setEditForm(true);
    };

    const openModalDelete = () => {
        setDeleteForm(true);
    };

    const openModalShare = () => {
        setShareForm(true);
    };
    const closeModal = () => {
        if (editForm)
            setEditForm(false);
        else if (deleteForm)
            setDeleteForm(false);
        else
            setShareForm(false)
    };


    const deleteFile = () => {
        socketRef.current.emit("DOCS_DELETE_DOCUMENT", { docId: document._id, userId: currentUser._id }, (response, projects) => {
            if (response === '400') {
                //const message = `Please delete the file from the following projects first: ${formatProjectNames(projects)}`;
                //openErrorPopup(message);
            } else {
                //onClose();
            }
        });
    };

    return (
        <>
            <div className="card sales-card">
                <div className="filter">
                    <a className="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots" style={{ fontSize: "20px" }}></i></a>
                    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow" data-popper-placement="bottom-end" style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate3d(0px, 30px, 0px)" }}>
                        {isOwner && <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#editModel" onClick={() => { setSelectedDocument(document) }}><i className="bi bi-vector-pen"></i>Edit</a></li>}
                        {isOwner && <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#shareModel" onClick={() => { setSelectedDocument(document) }}><i className="bi bi-share"></i>Share</a></li>}
                        {activeConference && <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#sendToModel" onClick={() => { setSelectedDocument(document) }}><i className="bi bi-send"></i>Send to</a></li>}
                        <li><a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#deleteModel" onClick={() => { setSelectedDocument(document) }}><i className="bi bi-trash"></i>Delete</a></li>
                    </ul>
                </div>

                <div className="card-body">
                    <h5 className="card-title">Owner <span>| {document?.users[0]?.name}</span></h5>

                    <div className="d-flex align-items-center">
                        <div className="card-icon d-flex align-items-center justify-content-center" style={{ backgroundColor: "transparent" }}>
                            <i className={iconClassName}></i>
                        </div>
                        <div className="ps-3">
                            <h5>{document.name}</h5>
                            <span className="text-muted small pt-2 ps-1">Added at: </span><span className="text-success small pt-1 fw-bold">{document.addedAt}</span>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DocumentComponent;